import { Row, Col, Card, Image, Ratio } from "../../bootstrap.js";
import "./pdfViewer.css";

export function PDFViewer({ title, file, styling }) {


  return (
    <div className={styling}>
      <Ratio aspectRatio="16x9">
        <iframe title={title} src={`/files/${file}.pdf`}/>
      </Ratio>
    </div>
  );
}
