import { Row, Col, Card, Image } from "../../bootstrap.js";
import "./research.css";
import "./Projects/projects.css";
import { Methods2 } from "./Methods2.js";
import { Projects2 } from "./Projects/Projects2.js";
import { ProjectsDepth2 } from "./Projects/ProjectsDepth2.js";
import { useMediaQuery } from "usehooks-ts";
import { HeroImage2 } from "../HeroImage/HeroImage2.js";
import { useState } from "react";

export function Research2() {

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  }; 



  const diseases = [
    "Neonatal Respiratory Distress Syndrome (RDS)",
    "Acute Respiratory Distress Syndrome (ARDS)",
    "Pulmonary Alveolar",
    "Proteinosis (PAP)",
    "Idiopathic Pulmonary Fibrosis (IPF)",
    "Sarcoidosis",
    "Asthma",
    "Bacterial Pneumonia",
  ];

  const isMobile = useMediaQuery("(max-width: 576px)");

  return (
    <div>
      {/* {isMobile ? (
        <>
          <h2 className="redHeaderResearch topH">Research</h2>
        </>
      ) : (
        <></>
      )} */}

      {/* <HeroImage2 imgUrl="researchCoverPic2.png"></HeroImage2> */}
    

      <Col className="research">
        <div className="researchHero">
      <h3 className="redHeaderResearch"> Research</h3>
          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} className="imgOverview">
                  <Image
                    src="/images/Research/laboverview.png"
                    className="img-fluid"
                    alt="Diagram of pulmonary surfactant zoomed in from the lung."
                  ></Image>
                </Col>
                </div>
        <Col className="intro">
         
          <div className="firstSecResearch">
            {isMobile ? (
              <></>
            ) : (
              <>
                <div className="researchSideBar">
                  <h4 className="blackHeaderResearchMain">Focus</h4>
                </div>
              </>
            )}

            <Col xs={11} sm={11} md={11} lg={11} className="researchRightSide">
              {isMobile ? (
                <>
                  {" "}
                  <h4 className="blackHeaderResearchMain">Focus</h4>
                </>
              ) : (
                <></>
              )}
              <p className="researchFocus">
              We model pulmonary surfactant (PS) at the molecular level using various computational techniques to understand the different components of pulmonary surfactant to advance human health.
              </p>
            </Col>
          </div>

          {/* <Row>
            <Col xs={12} sm={12} md={12} lg={12} className="researchOverview">
              {isMobile ? (
                <></>
              ) : (
                <>
                  <div className="researchSideBar">
                    <h4 className="blackHeaderResearchMain">Overview</h4>
                  </div>
                </>
              )} */}

              {/* <div className="overviewCont"> */}
              {/* <Col xs={11} sm={11} md={11} lg={11} className="overviewCont">
                {isMobile ? (
                  <>
                    <h4 className="blackHeaderResearchMain fixToLeft">
                      Overview
                    </h4>
                  </>
                ) : (
                  <></>
                )} */}

            

                {/* <Col xs={1} sm={1} md={1} lg={1} className="centerArrow">
                  {isMobile ? (
                    <Image
                      src="/images/Research/blueDownArrow.svg"
                      className="img-fluid rightArrow"
                      alt="A blue downward facing arrow."
                    ></Image>
                  ) : (
                    <Image
                      src="/images/Research/blueRightArrow.svg"
                      className="img-fluid rightArrow"
                      alt="A blue rightward facing arrow."
                    ></Image>
                  )}
                </Col> */}

                {/* <Col xs={2} sm={2} md={2} lg={2} className="thera">
                  <Image
                    src="/images/Research/therapeutics.png"
                    className="img-fluid therapeutics"
                  ></Image>
                  <p>Therapeutics</p>
                </Col> */}
              {/* </Col> */}
              {/* </div> */}
            {/* </Col>
          </Row> */}
        </Col>
        {/* <section className="projects">
          <Projects2></Projects2>
        </section> */}
          <section className="projects2">
          <ProjectsDepth2 index={index} handleSelect={handleSelect}></ProjectsDepth2>
        </section>
        <section className="methods">
          <Methods2 setIndex={setIndex}></Methods2>
        </section>
      
      </Col>
    </div>
  );
}


