import { Row, Col, Card, Image } from "../../bootstrap.js";
import "./logos.css";

export function Logos() {
    return (
        <Row className="logos">
            <Row className="iconsLog">
                <Col>
                    <Image src="/images/HomePics/nytLogoWhite.png"
                        className="img-fluid nytLogo"
                        alt="New York Times Logo"></Image>
                </Col>

                <Col>
                    <div className="nprCont">
                    <Image src="/images/HomePics/nprLogo.jpg"
                        className="img-fluid nprLogo"
                        alt="NPR logo"></Image>
                    <p className="npr">NPR</p>
                    </div>
                </Col>
                <Col className="logoCont2">
                    <Image src="/images/HomePics/unLogo.png"
                        className="img-fluid unLogo"
                        alt="UN logo"></Image>
                </Col>
                <Col>
                    <Image src="/images/HomePics/nature.svg"
                        className="img-fluid nature"
                        alt="nature Logo"></Image>
                </Col>
                {/* <Col className="tumbleCont"> */}
                    <Image src="/images/HomePics/tumble.png"
                        className="img-fluid tumble"
                        alt="Tumble Media logo"></Image>
                {/* </Col> */}
            </Row>
        </Row>
    )
}