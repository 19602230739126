import { Row, Col, Card, Image } from "../../bootstrap.js";
import "./groupMembers.css";
import { useMediaQuery } from "usehooks-ts";

export function Mona({ members }) {
  const isMobile = useMediaQuery("(max-width: 992px)");

  return (
    <section>
      <Row className="group2Row">
        {members.map(
          (member, i) =>
            i > 0 && (
              <div>
                <Col xs={12} sm={12} className="">
                <div className="positionsGroup">
                  <div className="floatImgMonaCont">
                  <Image
                    src={`/images/Group/${member.img}.jpg`}
                    className="img-fluid memberPic floatImgMona"
                    alt={`${member.alt}`}
                  ></Image>
                  </div>
              
                      <header>
                        <h4 className="groupName">{member.name}</h4>
                      </header>
                      
                        <h5 className="groupTitle">{member.title}</h5>
                        <h5 className="groupTitle">
                          Affiliate Faculty, Chemistry and Chemical Biology
                        </h5>
        
                       
                    
                          
<div className="researchGroup">
              
              <p className="researchText">
              Dr. Mona Minkara is the head of the COMBINE (Computational Modeling for Biointerface Engineering) Lab at Northeastern University. Her research is focused on studying pulmonary surfactant (PS), which is a complex protein-lipid substance secreted by the alveoli in the lungs. Dr. Minkara specializes in using different computational tools to investigate biomolecules and engineer new solutions. Her lab is pioneering new approaches that combine protein and surfactant modeling to investigate the PS system computationally, with the aim of discovering potential therapeutic applications. She is the recipient of a 2024 NSF CAREER Award. 
As a blind scientist, Dr. Minkara is dedicated to making science more accessible and inclusive. She is actively involved in engineering new tools to make science accessible to the blind.


              </p>
              
            </div>  
                          <div className="groupSoc1">
                            <div className="groupSocCont">
                            <a href="https://www.linkedin.com/in/monaminkara/">
                              <i class="bi bi-linkedin"></i>
                            </a>
                            <a href="https://www.youtube.com/channel/UCYBXlV5HoElWXs5zCKNEtaQ">
                              <i class="bi bi-youtube"></i>
                            </a>
                            <a href="https://monaminkara.com/" 
									><Image 
											 src="/images/logo/mmi.png"
											className="mLogo"/></a>
                            </div>
                          </div>
                      
                        
                      </div>
                    
                      <hr className="hrLine"></hr>
                      <h5 className="groupTitle">
                        Education
                        </h5>
           <div>        
<p className="edp edpH">Post-doctoral Research Associate, University of Minnesota </p>
<p className="descEd edp">Advisor: Prof. J. Ilja Siepmann </p>

<p className="descEd edp">Funded by Ford Foundation National Academies of Sciences, Engineering, and Medicine (NASEM) Postdoctoral Fellowship </p>

<p className="edp edpH">Ph.D. in Chemistry, University of Florida  </p>
<p className="descEd edp">Thesis: "Design of a Novel Inhibitor for Helicobacter pylori urease" </p>
<p className="descEd edp">Funded by NSF Graduate Research Fellowship </p>
<p className="descEd edp">Advisors: Prof. Kenneth M. Merz Jr. & Prof. Erik Deumens</p>


<p className="edp edpH">B.A. in Chemistry & Middle Eastern Studies, Wellesley College</p> 
<p className="descEd edp">Thesis: "Analyzing the Binding of Nevirapine and Rilpivirine to HIV-1 Reverse Transcriptase through Computationally Derived Charge Optimization"</p> 
<p className="descEd edp">Advisor: Prof. Mala Radhakrishnan</p> 

</div> 

                     

                
                
                  
                </Col>
              </div>
            )
        )}
      </Row>
    </section>
  );
}
