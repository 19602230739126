import { Row, Col, Card, Image } from "../../bootstrap.js";
import * as data from "./groupMembersData.js";
import { GroupMembers } from "./GroupMembers.js";
import { GroupMembers2 } from "./GroupMembers2.js";
import { GroupMembersAccess } from "./GroupMembersAccess.js";
import { GroupMembersAlumni } from "./GroupMembersAlumni.js";
import { PrevMembers } from "./PrevMembers.js";
import "./group.css";
import { HeroImage2 } from "../HeroImage/HeroImage2.js";
import {Mona} from "./Mona.js";

export function Group() {
  return (
    <div>
      <div className="groupHero">
         <header>
          <h3 className="labMembersTop">Group</h3>
        </header>
        <Col className="heroGroup">
      <Image src="/images/Group/group4.png"
      className="img-fluid groupPicHero"
      alt="Group members all standing in a line with one holding a cake."></Image>
      </Col>
      {/* <HeroImage2 imgUrl="group2.png"></HeroImage2> */}
      </div>
      {/* <section className="groupWrapper">
        <div className="linearGradient"> </div>
        <div className="groupCont">
          <h2 className="groupCard">Group</h2>
        </div>
        <Image src="/images/labGroup.jpg" className="bgPic"></Image>
      </section> */}
     
      <div className="groupContainer">
      {/* <header>
          <h3 className="labMembers">Group</h3>
        </header> */}
      {/* <Col className="heroGroup">
      <Image src="/images/croppedGroup.png"
      className="img-fluid groupPicHero"
      alt="Group members all standing in a line with one holding a cake."></Image>
      </Col> */}
        <header>
          <h3 className="labMembers">Principal Investigator</h3>
        </header>
        <section>
          <Mona members={data.PI}></Mona>
        </section>
        <header>
          <h3 className="labMembers">PhD Group Members</h3>
        </header>
        <section>
          <GroupMembers members={data.PhDStudents}></GroupMembers>
        </section>
        {/* <section className="groupSec">
          <GroupMembers members={data.mastersStudents}></GroupMembers>
        </section> */}
        <header>
          <h3 className="labMembers">Post Baccalaureate Group Members</h3>
        </header>
        <sesction>
        <GroupMembers2 members={data.postBaccalaureate}></GroupMembers2>
        </sesction>
        <header>
          <h3 className="labMembers">Undergraduate Group Members</h3>
        </header>
        <section>
          <GroupMembers2 members={data.undergraduateStudents}></GroupMembers2>
        </section>
        <header>
          <h3 className="labMembers">Access Assistants</h3>
        </header>
        <section>
          <GroupMembersAccess members={data.accessAssistants}></GroupMembersAccess>
        </section>
       
      </div>
      <div className="alumniBG">
        <header>
          <h3 className="labMembers">Alumni</h3>
        </header>
        <section>
          <PrevMembers members={data.alumni}></PrevMembers>
        </section>
        </div>
    </div>
  );
}
