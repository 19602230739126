import { Row, Col, Card, Image, Carousel } from "../../../bootstrap.js";
import { LinkButtonDark } from "../../Button/Link/LinkButtonDark.js";
import { PDFViewer } from "../../PDF/PDFViewer.js";
import "./projects.css";
import "../research.css";
import { useMediaQuery } from "usehooks-ts";



export function ProjectsDepth2() {
  const isMobile = useMediaQuery("(max-width: 576px)");




  return (
    <>
      {isMobile ? (
        <></>
      ) : (
        <div className="researchSideBar">
          <h3 className="redSmHeaderResearch">Programs</h3>
        </div>
      )}
      <Col lg={12} className="projectsCol">
        {isMobile ? (
          <></>
        ) : (
          <div className="researchSideBar">
            <h4 className="blackHeaderResearchNum">
              <i
                class="bi bi-1-square-fill stylingNum"
                aria-label="Program 1"
              ></i>
            </h4>
          </div>
        )}
        <Col xs={12} sm={12} lg={11} className="projSec">
          {isMobile ? (
            <>
              <h3 className="redSmHeaderResearch">Programs</h3>
              <div className="headerNumMobile">
                <h4 className="blackHeaderResearchNum">
                  <i
                    class="bi bi-1-square-fill stylingNum"
                    aria-label="Program 1"
                  ></i>
                </h4>
              </div>
            </>
          ) : (
            <>
              <h4 className="blackHeaderProjects">Immunoproteins</h4>
            </>
          )}

          <h5 className="redHeaderProjects">
            Investigating the structure and function of surfactant proteins and collectins
          </h5>
          <p>
            The immunoproteins SP-A and SP-D are part of a group known as
            collagen C-type lectins which are found throughout the body. The
            C-type lectins, part of the body’s natural immune response,
            including SP-A, SP-D, and MBL, are known for their ability to
            interact with the pathogen by binding the pathogenic surface glycan.
            They play an important role in the innate immune system to inhibit
            pathogens' activities.
          </p>
          <Row className="researchPaperRow">
            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="program1Pics">
              {/* <Image
                src="/images/Research/project1.png"
                className="img-fluid"
                alt="Immunoproteins that appear as a cluster of tight-knit 3-D spiral curls in purple, yellow and teal."
              ></Image> */}
              <Image
                src="/images/Research/graphical1.jpg"
                className="img-fluid graphical1"
                alt="A graphical abstract of SP-D and trimannose"
              ></Image>
              <p className="captionsPics">DM SP-D with Trimannose</p>
            </Col>
            <Col md={6} className="carCont" id="pdfViewer1">
          
            <Carousel id="carousel" className="carouselProjects carousel-dark">
                <Carousel.Item >  
                <PDFViewer 
                title="Elucidating the enhanced binding affinity of a double mutant
                SP-D with trimannose on the influenza A virus using molecular
                dynamics"
                file="15_elucidating"
                styling="researchPDF2"
              ></PDFViewer>
                <a href="/Publications">
                <div className="carCaptions">
<p className="captionsPicsCar1">Elucidating the Enhanced Binding Affinity of a Double Mutant SP-D With Trimannose on the Influenza A Virus Using Molecular Dynamics</p>
                <p className="captionsPicsCar">Li, D.; Minkara, M.S.</p>
                <p className="captionsPicsCar">Computational and Structural Biotechnology Journal, 2022</p>
                </div>
                </a>
            </Carousel.Item>
               
              
</Carousel>
</Col>

            {/* <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="pdfproj" id="pdfViewer1">
              <div className="contPdf">
              <PDFViewer 
                title="Elucidating the enhanced binding affinity of a double mutant
                SP-D with trimannose on the influenza A virus using molecular
                dynamics"
                file="15_elucidating"
                styling="researchPDF"
              ></PDFViewer>
              </div>
               <div className="carCaptions">
               <p className="captionsPicsCar1">Elucidating the Enhanced Binding Affinity of a Double Mutant SP-D With Trimannose on the Influenza A Virus Using Molecular Dynamics</p>
                <p className="captionsPicsCar">Li, D.; Minkara, M.S.</p>
                <p className="captionsPicsCar">Computational and Structural Biotechnology Journal, 2022</p>
                </div>
             

            </Col> */}
          </Row>
          <Row>
          <h5 className="redHeaderProjects">
             We also conduct docking studies of immunoproteins to glycans
          </h5>
          <Row className="researchPaperRow">
            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="program1Pics">
            <Image
                src="/images/Research/2D Ligand Interaction Binding Map.png"
                className="img-fluid"
                alt="2D Ligand Interaction Binding Map"
              ></Image>
              <p className="captionsPics">2D Ligand Binding Interaction Map</p>
              <p></p>
              <Image
                src="/images/Research/Mannose-Binding-Lectin.png"
                className="img-fluid"
                alt="Mannose-Binding-Lectin"
              ></Image>
              <p className="captionsPics">The ligand-binding human protein, MBL. Calcium Ions in Pink, Oxygen Red, Hydrogen White, Nitrogen Blue, Grey indicates carbon/bonded area.</p>
             <p></p>
              <Image
                src="/images/Research/Mannose Binding Lectin 2.png"
                className="img-fluid"
                alt="Mannose-Binding-Lectin"
              ></Image>
              <p className="captionsPics">MBL, captured in ChimeraX, with structural data from Schrodinger Suite's Maestro.</p>
            </Col>
            </Row>
          </Row>
        </Col>
      </Col>
      <Col lg={12} className="projectsCol">
        {isMobile ? (
          <></>
        ) : (
          <div className="researchSideBar">
            <h4 className="blackHeaderResearchNum">
              <i
                class="bi bi-2-square-fill stylingNum"
                aria-label="Program 2"
              ></i>
            </h4>
          </div>
        )}

        <Col xs={12} lg={11} className="projSec">
          {isMobile ? (
            <div className="headerNumMobile">
              <h4 className="blackHeaderResearchNum">
                <i
                  class="bi bi-2-square-fill stylingNum"
                  aria-label="Program 2"
                ></i>
              </h4>
              <h4 className="blackHeaderProjects">Alveolar Interface</h4>
            </div>
          ) : (
            <>
              <h4 className="blackHeaderProjects">Alveolar Interface</h4>
            </>
          )}

          <h5 className="redHeaderProjects">
            The study, understanding, and modeling of the monolayer to bilayer
            transition of pulmonary surfactant to engineer a more effective synthetic pulmonary surfactant 
          </h5>
          <p>
          This program is working to understand the impact pulmonary surfactant proteins and
lipids have on the monolayer to bilayer transition during the breathing process through
understanding how pulmonary surfactant proteins (SP-B and SP-C) and pulmonary
surfactant lipids (zwitterionic saturated and unsaturated phospholipids, cholesterol, and
negative unsaturated phospholipids) impact the monolayer to bilayer transition and how
pulmonary surfactant regulates the surface tension.
          </p>
          <Row className="researchPaperRow">
            <Col md={6} className="program1Pics">
              <Image
                src="/images/Research/project2.png"
                className="img-fluid"
                alt="A diagram of the different layers of PS proteins and PS lipids."
              ></Image>
              <p className="captionsPics">Pulmonary Surfactant System in Setting of Alveolus</p>
            </Col>
            {/* <Col md={3}>
            <h5>Research Paper</h5>
            <p>
              Elucidating the enhanced binding affinity of a double mutant SP-D
              with trimannose on the influenza A virus using molecular dynamics
            </p>
            <LinkButton></LinkButton>
          </Col> */}
          </Row>
        </Col>
      </Col>
      <Col lg={12} className="projectsCol">
        {isMobile ? (
          <></>
        ) : (
          <div className="researchSideBar">
            <h4 className="blackHeaderResearchNum">
              <i
                class="bi bi-3-square-fill stylingNum"
                aria-label="Program 3"
              ></i>
            </h4>
          </div>
        )}
        <Col xs={12} lg={11} className="projSec">
          {isMobile ? (
            <div className="headerNumMobile">
              <h4 className="blackHeaderResearchNum">
                <i
                  class="bi bi-3-square-fill stylingNum"
                  aria-label="Program 3"
                ></i>
              </h4>
              <h4 className="blackHeaderProjects">Drug Delivery</h4>
            </div>
          ) : (
            <>
              <h4 className="blackHeaderProjects">Drug Delivery</h4>
            </>
          )}

          <h5 className="redHeaderProjects">
            Modeling drug delivery mechanisms
          </h5>
          <p>
            This program delves into the complexities of micellar and bilayer
            molecular structures, primarily focusing on enhancing our
            understanding of drug delivery and their interactions with the
            pulmonary surfactant (PS) lipid layer. This research is crucial for
            developing more effective and targeted drug delivery methods,
            especially in the context of the respiratory system, an increasingly
            targeted area for drug delivery due to its high permeability, large
            adsorptive surface area, and good blood supply. While drugs are
            often slowly cleared through the lungs, resulting in poor
            absorption, our program aims to exploit this knowledge and utilize
            the moieties of pulmonary surfactant as potential drug carriers for
            inhalation therapy.
          </p>
          <Row className="researchPaperRow">
            <Col md={6} className="program1Pics">
              <Image
                src="/images/Research/project3.png"
                className="img-fluid"
                alt="A diagram on how DPPC lipid can serve as a drug delivery method."
              ></Image>
                            <p className="captionsPics">The PSM and Micelle Model</p>

            </Col>
            {/* <Col md={3}>
            <h5>Research Paper</h5>
            <p>
              Elucidating the enhanced binding affinity of a double mutant SP-D
              with trimannose on the influenza A virus using molecular dynamics
            </p>
            <LinkButton></LinkButton>
          </Col> */}
          </Row>
        </Col>
      </Col>
      <Col lg={12} className="projectsCol">
        {isMobile ? (
          <></>
        ) : (
          <div className="researchSideBar">
            <h4 className="blackHeaderResearchNum">
              <i
                class="bi bi-4-square-fill stylingNum"
                aria-label="Program 4"
              ></i>
            </h4>
          </div>
        )}
        <Col xs={12} lg={11} className="projSec">
          {isMobile ? (
            <div className="headerNumMobile">
              <h4 className="blackHeaderResearchNum">
                <i
                  class="bi bi-4-square-fill stylingNum"
                  aria-label="Program 4"
                ></i>
              </h4>
              <h4 className="blackHeaderProjects">Computational Methods</h4>
            </div>
          ) : (
            <>
              <h4 className="blackHeaderProjects">Computational Methods</h4>
            </>
          )}
          <h5 className="redHeaderProjects">
            Engineering computational techniques and methods for the study of
            biological interfaces
          </h5>
          <p>
            We are tracking and refining the methods we use to model pulmonary
            surfactant, such as best computational techniques and force fields,
            as we accomplish programs 1-3. For example, one project is
            developing a hybrid force field for proteins. Our group is working
            to develop a hybrid force field, an inversion of the previous work
            done by Kar and Feig, that involves coarse-graining the backbone of
            a protein and atomistically modeling side chains to utilize both MC
            and MD in a single simulation protocol, alternating between the two
            for optimal equilibration efficiency, and employing force fields,
            already well-known for their accuracy, to model the surfactant
            proteins of interest. We are working to create a program allowing
            for any partitioning and modeling of biological systems while
            enabling users to obtain parameters for their choice of
            coarse-grained or atomistic beads. If needed, our group will extend
            the existing force fields to allow for the study of such complex
            biological systems.
          </p>
          <Row className="researchPaperRow">
            <Col md={6} className="program1Pics">
              <Image
                src="/images/Research/project4.png"
                className="img-fluid"
                alt="A force field of surfactant proteins."
              ></Image>
               <p className="captionsPics">Hybrid Force Field</p>
            </Col>
            {/* <Col md={3}>
            <h5>Research Paper</h5>
            <p>
              Elucidating the enhanced binding affinity of a double mutant SP-D
              with trimannose on the influenza A virus using molecular dynamics
            </p>
            <LinkButton></LinkButton>
          </Col> */}
          </Row>
        </Col>
      </Col>
      <Col lg={12} className="projectsCol">
        {isMobile ? (
          <></>
        ) : (
          <div className="researchSideBar">
            <h4 className="blackHeaderResearchNum">
              <i
                class="bi bi-5-square-fill stylingNum"
                aria-label="Program 5"
              ></i>
            </h4>
          </div>
        )}
        <Col xs={12} lg={11} className="projSecLast">
          {isMobile ? (
            <div className="headerNumMobile">
              <h4 className="blackHeaderResearchNum">
                <i
                  class="bi bi-5-square-fill stylingNum"
                  aria-label="Program 5"
                ></i>
              </h4>
              <h4 className="blackHeaderProjects">
              Multisensory Science
              </h4>
            </div>
          ) : (
            <>
              <h4 className="blackHeaderProjects">
              Multisensory Tools
              </h4>
            </>
          )}

          <h5 className="redHeaderProjects">
          Redefining accessibility and innovation in science with non-visual
techniques
          </h5>
          <p>
          We are dedicated to more than just advancing bioengineering research; we're transforming how
science is done. "Multisensory Tools" isn't just about creating accessibility; it's about
enhancing the scientific process for everyone by integrating diverse sensory experiences into
STEM research. Our work develops pioneering tools and techniques that not only make science
accessible to individuals who are blind but also enrich the toolkit of all researchers. These
innovations expand how we communicate and comprehend scientific data. By integrating
multisensory approaches, we aim to uncover unique insights and foster inclusive scientific
environments that inspire discovery across all sensory abilities.
          </p>
          <Row className="researchPaperRow">
            <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6} className="program1Pics">
            <Image src="/images/HomePics/program5Home.png"
            className="img-fluid p5img"
            alt="A zoomed in photo of fingers touching a lithophane of a protein"></Image>
                <p className="captionsPics">Lithophane of a Protein</p>
            </Col>
           
            <Col md={6} className="carCont">
            <Carousel id="carousel" className="carouselProjects carousel-dark">
                <Carousel.Item >  
                <PDFViewer
                  title="Universal Pictures: A Lithophane Codex Helps Teenagers With Blindness Visualize Nanoscopic Systems"
                  file="18_universalPictures"
                  styling="researchPDF2"
                ></PDFViewer>
                <a href="/Publications">
                <div className="carCaptions">
<p className="captionsPicsCar1">Universal Pictures: A Lithophane Codex Helps Teenagers With Blindness Visualize
Nanoscopic Systems</p>
                <p className="captionsPicsCar">Alonzo, E.A.; Lato, T.J.; ... Minkara, M.; et al.</p>
                <p className="captionsPicsCar">Science Advances, 2024</p>
                </div>
                </a>

            </Carousel.Item>
                <Carousel.Item >
                <PDFViewer 
                  title="Data for All: Tactile Graphics That Light up With Picture-Perfect Resolution"
                  file="14_data"
                  styling="researchPDF2"
                ></PDFViewer>
                <a href="/Publications">
                <div className="carCaptions">
<p className="captionsPicsCar1">Data for All: Tactile Graphics That Light up With Picture-Perfect Resolution</p>
                <p className="captionsPicsCar">Minkara, M.; Koone, J.C.; Dashnaw, C.M.; et al.</p>
                <p className="captionsPicsCar">Science Advances, 2022</p>
                </div>
                </a>
              </Carousel.Item>
              <Carousel.Item >
                  
                 
                        <iframe
                            src="https://www.npr.org/player/embed/1118521010/1118521011"
                            frameborder="0"
                            scrolling="no"
                            title="NPR embedded audio player"
                            className="nprIframe"
                        ></iframe>
                               
                    
                </Carousel.Item>
              
</Carousel>
</Col>
            {/* <Col md={6} className="researchPaper">
              <div className="pdfCont">
                <PDFViewer
                  title="Universal Pictures: A Lithophane Codex Helps Teenagers With Blindness Visualize Nanoscopic Systems"
                  file="18_universalPictures"
                  styling="researchPDF2"
                ></PDFViewer>
              </div>
              <div className="pdfCont">
                <PDFViewer
                  title="Data for All: Tactile Graphics That Light up With Picture-Perfect Resolution"
                  file="14_data"
                  styling="researchPDF2"
                ></PDFViewer>
              </div>
            </Col> */}
            
          </Row>
        </Col>
      </Col>
    </>
  );
}
