import { Row, Col, Card, Image } from "../../bootstrap.js";
import "./groupMembers.css";
import { useMediaQuery } from "usehooks-ts";

export function PrevMembers({ members }) {
  const isMobile = useMediaQuery("(max-width: 992px)");

  return (
    <Row className="group2Row gy-2">
      {members.map(
        (member, i) =>
          i > 0 && (
            <>
              <Col sm={12} md={12} lg={12} className="img2ContAlumni">
                <h5>{member.name}</h5>
                <p>{member.position}</p>
              </Col>

              {/* <p>{member.bio}</p> */}
            </>
          )
      )}
    </Row>
  );
}
